<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
  >
    <path
      d="M9.7738 2.69678H11.254L8.02106 7.18947L11.824 13.3039H8.84718L6.51394 9.59643L3.84704 13.3039H2.36444L5.82169 8.49734L2.1759 2.69678H5.22833L7.33524 6.08549L9.7738 2.69678ZM9.25401 12.2278H10.0737L4.7817 3.71667H3.90131L9.25401 12.2278Z"
      fill="#FCFCFC"
    />
  </svg>
</template>

<style scoped></style>
