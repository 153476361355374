<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
  >
    <g clip-path="url(#clip0_2567_2553)">
      <path
        d="M13.1802 2.33111C12.9732 2.12264 12.6791 2.05317 12.4124 2.14977L1.10131 6.24901C0.79305 6.36072 0.584007 6.66325 0.568756 7.01966C0.55353 7.37611 0.735815 7.69888 1.03315 7.84194L3.83048 9.1875C3.84412 9.25711 4.64715 13.3518 4.67447 13.491C4.70952 13.6698 4.79205 13.829 4.9669 13.878C5.14376 13.9275 5.2687 13.8213 5.40066 13.7126C5.4741 13.6522 7.47652 12.0038 7.47652 12.0038L9.90335 14.187C10.0447 14.3143 10.2175 14.3806 10.3936 14.3806C10.4775 14.3806 10.5622 14.3656 10.6445 14.3348C10.9 14.2396 11.0893 14.0104 11.151 13.7218L13.4115 3.15604C13.4757 2.85564 13.3871 2.53958 13.1802 2.33111ZM5.58149 9.95853C5.58011 9.96213 5.57875 9.96619 5.57747 9.97105L5.10954 11.7684L4.58733 9.10571L8.17817 6.91232L5.66762 9.7983C5.62807 9.84376 5.59898 9.89873 5.58149 9.95853ZM5.73001 12.4038L5.94254 11.5875L6.14583 10.8066L6.87424 11.4619L5.73001 12.4038ZM12.676 2.96583L10.4156 13.5316C10.4145 13.5368 10.4129 13.5441 10.4019 13.5482C10.3909 13.5523 10.3856 13.5476 10.3818 13.5442L7.72632 11.1552C7.72624 11.1551 7.72619 11.155 7.72612 11.155L6.49575 10.0481L10.4485 5.50417C10.5776 5.35575 10.5887 5.1259 10.4746 4.96334C10.3604 4.80075 10.1553 4.75432 9.99205 4.85409L4.13907 8.42928L1.33601 7.08093C1.32747 7.07684 1.32277 7.07455 1.32345 7.05867C1.32412 7.04284 1.329 7.04104 1.33784 7.03784L12.649 2.93862C12.6545 2.93666 12.6606 2.93439 12.6697 2.94351C12.6788 2.95268 12.6773 2.95964 12.676 2.96583Z"
        fill="#FCFCFC"
      />
    </g>
    <defs>
      <clipPath id="clip0_2567_2553">
        <rect
          width="12.8642"
          height="14.1429"
          fill="white"
          transform="translate(0.567871 0.928711)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped></style>
